<template>
  <div>
    <h1 class="c-blue fw-bolder mb-5">
      Alert Messaggi
    </h1>
    <div class="mx-4">
      <div
        v-for="communication in communications"
        :key="communication.id"
        class="communication mb-4 pb-2"
      >
        <h3 class="mb-2">
          {{ communication.title }}
        </h3>
        <p v-html="communication.content"></p>
        <h6 class="fw-bold text-end p-3">
          {{ new Date(communication.created_at).toLocaleDateString() }}
        </h6>
        <hr />
      </div>
    </div>
    <div class="mt-4"></div>
    <v-pagination
        v-model="pageNumber"
        :pages="totalPage"
        @update:modelValue="switchPage"
    />
  </div>
</template>

<script>
import { userCommunications } from "@/api/communication.api";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import "@/assets/css/paginator.css";

export default {
  name: "Communications",
  components: {
    VPagination
  },
  data() {
    return {
      communications: [],
      totalPage: 0,
      pageNumber: 1,
    }
  },
  methods: {
    async getUserCommunications(page = 1) {
      const response = await userCommunications(page);
      this.communications = response.data;
      this.totalPage = response.meta.last_page;
    },
    switchPage(page) {
      this.pageNumber = page;

      this.getUserCommunications(page);
    }
  },
  mounted() {
    this.getUserCommunications();
  }
}
</script>

<style scoped>

</style>